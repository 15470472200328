import React, { Component } from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import { BlogListing } from "../components/BlogListing"
import { CgbCalloutCtaBlock } from "../components/blocks/CgbCalloutCtaBlock"

/**
 * This page should query the posts based on the context provided and pass the results to the post-list block
 * No post-list block? Show an error
 */
class ListPosts extends Component {
  render() {
    const meta = {}
    const { name, themeOptions } = this.props.data.wp
    const { pagePath } = this.props.pageContext

    this.props.pageContext.posts = this.props.data.wp.category.posts.edges

    let subscribeObject = {
      attributes: {
        className: "mb-0",
        contained: false,
      },
      innerBlocks: [
        {
          attributes: {
            align: null,
            backgroundColor: null,
            className: null,
            content:
              "Subscribe to our monthly newsletter and stay up to date with all news and events.",
            direction: null,
            textColor: null,
            __typename: "wp_CoreParagraphBlockAttributes",
          },
          innerBlocks: [],
          name: "core/paragraph",
          __typename: "wp_CoreParagraphBlock",
        },
        {
          attributes: {
            align: "right",
            backgroundColor: "primary",
            borderRadius: 4,
            className: null,
            gradient: null,
            linkTarget: "",
            placeholder: null,
            rel: "",
            text: "Subscribe",
            textColor: "foreground_primary",
            title: "",
            url: "/subscribe/",
            __typename: "wp_CoreButtonBlockAttributes",
          },
          innerBlocks: [],
          name: "core/button",
          __typename: "wp_CoreButtonBlock",
        },
      ],
    }

    let faqs = null;

    return (
      <Layout
        meta={meta}
        path={pagePath}
        cta={this.props.data.wp.category.PageCTA}
        title={name}
        themeOptions={themeOptions}
        pageContext={this.props.pageContext}
        faqs={faqs}
      >
        <BlogListing
          cat={this.props.data.wp.category}
          sticky={this.props.data.wp.posts}
          pageContext={this.props.pageContext}
        />

        <CgbCalloutCtaBlock
          attributes={subscribeObject.attributes}
          innerBlocks={subscribeObject.innerBlocks}
        />
      </Layout>
    )
  }
}

export const query = graphql`
  query($id: ID!, $termID: Int!) {
    wp {
      category(id: $id) {
        id
        slug
        name
        PageCTA {
          actionType
          ctaText
          fieldGroupName
          link {
            url
          }
        }
        posts(first: 100) {
          edges {
            node {
              id
              slug
              uri
              title
              excerpt
              date
              featuredImage {
                node {
                  id
                  mediaItemUrl
                  mediaItemUrlSharp {
                    id
                    publicURL
                    childImageSharp {
                      fluid(quality: 80) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                  altText
                  title
                }
              }
            }
          }
        }
      }
      posts(where: { isSticky: true, categoryId: $termID }, first: 1) {
        nodes {
          id
          slug
          uri
          title
          excerpt
          date
          featuredImage {
            node {
              mediaItemUrl
              mediaItemUrlSharp {
                id
                publicURL
                childImageSharp {
                  fluid(quality: 80) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
      themeOptions {
        accommodationActive
        roomBookingCTAText
        roomBookingImage
        roomBookingImageText
        roomBookingLink
        tableBookingAccountId
        tableBookingActive
        tableBookingBrandId
        tableBookingCTAText
        tableBookingImage
        tableBookingImageText
        tableBookingRestaurantId
        liveResSiteId
        ThemeOptions {
          siteLogo {
            mediaItemUrl
          }
        }
      }
    }
  }
`

export default ListPosts
